import "../scss/extendable.scss"

import {plugin_registry, PluginBase} from "nk-plugin-registry"

@plugin_registry.register('ExtendableExtender')
class ExtendableExtender extends PluginBase {
    constructor($node) {
        super($node);

        this.$togglChevrons = $node.querySelectorAll('[data-js-select=toggle-chevron]');
    }

    loaded($node) {
        super.loaded($node);
        this.$filter_items = [...document.querySelectorAll('[data-filter]')];

        // parse json only once and cache the results
        for (const $filter_item of this.$filter_items) {
            this.filter_items_map.set($filter_item, JSON.parse($filter_item.dataset['filter']))
        }

        this._install_event_listeners();
    }

    _install_event_listeners = () => {

        this.$togglChevrons.forEach(

            ($s) => $s.parentElement.addEventListener('click', this._toggle_visibility($s))
        )
    }

    _toggle_visibility = ($toggleChevron) => (e) => {
        const event_info = $toggleChevron.parentElement.parentElement.querySelector(
            '[data-js-select=hide-on-toggle]'
        )

        if (event_info.classList.contains("hidden")) {
            event_info.classList.remove("hidden");
            $toggleChevron.classList.remove("icon-chevron-down")
            $toggleChevron.classList.add("icon-chevron-up")
        } else {
            event_info.classList.add("hidden");
            $toggleChevron.classList.add("icon-chevron-down")
            $toggleChevron.classList.remove("icon-chevron-up")
        }
    }
}