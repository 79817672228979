import Swiper, {Navigation, Pagination} from "swiper";
import 'swiper/css/bundle';

import "../scss/news.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";


@plugin_registry.register("NewsSwiper")
class NewsSwiper extends PluginBase {
    constructor($node) {
        super($node);
        this._$node = $node;
    }

    loaded($node) {
        super.loaded($node);

        console.log(23, window.innerWidth)
        this._swiper = new Swiper(
            this._$node.querySelector('[data-js-select="swiper"]'),

            {
                modules: [Pagination, Navigation],
                slidesPerView: window.innerWidth < 516 ? 2 : 3,
                autoHeight: true,
                pagination: {
                  el: this._$node.querySelector('[data-js-select="swiper-pagination"]'),
                  clickable: true,
                },
                navigation: {
                    nextEl: this._$node.querySelector('[data-js-select="swiper-next"]'),
                    prevEl: this._$node.querySelector('[data-js-select="swiper-prev"]'),
                },
                speed: 500,
                loop: true,
            }

        );
    }
}
