import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';
// import your plugins here.

import "header/js/header.js";
import "extendable/js/extendable.js";
import "../../news/js/swiper.js";
import "../../events/js/events.js";

plugin_registry.init();


